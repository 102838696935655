.topBar {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    direction: var(--direction);
    width: 100%;
    background-color: var(--topbar-background-color);
    position: relative;
    z-index: 4;
}

.topBarTopContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    width: 100%;
    z-index: 4;
}

.topBarTopTitleContainer {
    height: 30px !important;
    width: 100%;
    background-color: var(--topbar-titlebackground-color);
    font-weight: bold;
    font-variant: all-small-caps;
    font-size: var(--text-size-xlarge);
    justify-content: center !important;
    z-index: 4;
}

.topBarTopTitleContainer img {
    margin-inline-end: 8px;
}

.topLogoContainer {
    margin-inline-start: 5px;
}

.topLogo {
    width: 33px;
    height: 33px;
    background: url("../images/Football_Mania_Icon_c.png") center center / 33px no-repeat;
    border-radius: 0;
}

.topLogoIOS {
    width: 33px;
    height: 33px;
    margin-right: 8px;
    background-color: var(--icon-default-color);
    background-size: contain;
    -webkit-mask: var(--icon-back-arrow) no-repeat 50% 50%;
    mask: var(--icon-back-arrow) no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.topBarSearchContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

input:focus,
textarea:focus {
    outline: none;
    border: none;
}

.topBarSearchContainer input {
    width: 100%;
    height: 30px;
    margin-inline-end: 60px;
    padding: 0;
    border: 0;
    border-radius: 0;
    background: transparent;
    color: var(--text-color);
    caret-color: var(--text-color-matchstatus);
    font-size: var(--text-size-xxlarge);
}

.topBarSearchResultsContainer {
    position: absolute;
    width: calc(100% - 35px);
    margin-inline-start: 30px;
    margin-top: 50px;
}

.topBarSearchResults {
    width: 100%;
    max-height: 400px;
    background-color: var(--topbar-search-result-background-color);
    position: absolute;
    overflow: scroll;
}

.topBarSearchResults .listViewMatchStatusMatch,
.topBarSearchResults .listViewItem {
    font-weight: normal;
}

.topBarSearchResults .listViewMatchStatusMatch {
    font-size: var(--text-size-large);
}

.topBarSearchResultItem {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 50px;
    cursor: pointer;
}

.topBarSearchResultItem img {
    padding: 4px;
    position: relative;
}
